<template>
  <div>
    <Card :parent_id="parent_id">
      <p class="ti">工作生活</p>
    </Card>

    <div class="content">
      <p>职业类别</p>
      <div style="display: flex">
        <occupation :data1="data1" @workData="work" />
      </div>
    </div>

    <div class="content">
      <p>买房情况</p>
      <div>
        <el-select v-model="houseStatu" placeholder="请选择">
          <el-option
            v-for="item in houseStatus"
            :key="item.value"
            :label="item.value"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="content">
      <p>买车情况</p>
      <div>
        <el-select v-model="buyCarStatu" placeholder="请选择">
          <el-option
            v-for="item in buyCarStatus"
            :key="item.value"
            :label="item.value"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="content">
      <p>是否喝酒</p>
      <div>
        <el-select v-model="doDrink" placeholder="请选择">
          <el-option
            v-for="item in doDrinks"
            :key="item.value"
            :label="item.value"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="content">
      <p>是否吸烟</p>
      <div>
        <el-select v-model="doSmake" placeholder="请选择">
          <el-option
            v-for="item in doSmakes"
            :key="item.value"
            :label="item.value"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 按钮 -->
    <Btn style="margin-top: 56px">
      <div class="continue" @click="bcjx">保存并继续</div>
      <div class="skip" @click="tg">跳过</div>
    </Btn>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Btn from "@/components/Card/Btn.vue";
import occupation from "@/components/occupation";
import local from "@/api/common/local.js";
import { infocur, infoUpdate } from "@/api/login/login.js";
import { subTk } from "@/api/member/member.js";
export default {
  data() {
    return {
      parent_id:'',//zujian
      input: "",
      options: [],
      houseStatus: [
        {
          value: "和家人同住",
          label: "0",
        },
        {
          value: "已购房",
          label: "1",
        },
        {
          value: "租房",
          label: "2",
        },
        {
          value: "打算婚后购房",
          label: "3",
        },
        {
          value: "住在单位宿舍",
          label: "4",
        },
      ],
      houseStatu: "",
      buyCarStatus: [
        {
          value: "已买车",
          label: "0",
        },
        {
          value: "未买车",
          label: "1",
        },
      ],
      buyCarStatu: "",
      doDrinks: [
        {
          value: "不喝酒",
          label: "0",
        },
        {
          value: "稍微喝一点酒",
          label: "1",
        },
        {
          value: "酒喝得很多",
          label: "2",
        },
        {
          value: "社交场合会喝酒",
          label: "3",
        },
      ],
      doDrink: "",
      doSmakes: [
        {
          value: "不吸烟",
          label: "0",
        },
        {
          value: "稍微抽一点烟",
          label: "1",
        },
        {
          value: "烟抽得很多",
          label: "2",
        },
        {
          value: "社交场合会抽烟",
          label: "3",
        },
      ],
      doSmake: "",
      value: "",
      workregion: "",
      data1: null,
    };
  },
  components: {
    Card,
    Btn,
    occupation,
  },
  created() {
    this.cs();
  },
  methods: {
    work(i) {
      this.workregion = i;
    },
    //初始化
    async cs() {
      let a = local.get("access_token");
      const { code, data } = await infocur(a);
      if (code == 0) {
        this.parent_id = data.user.id
        this.data1 = {
          occSmallCategory: data.user.occSmallCategory,
          occBigCategory: data.user.occBigCategory,
        };

        this.buyCarStatu = data.user.buyCarStatus;
        if (this.buyCarStatu == null) {
          this.buyCarStatu == null;
        } else {
          this.buyCarStatus.forEach((item) => {
            if (this.buyCarStatu == item.label) {
              this.buyCarStatu = item.value;
            }
          });
        }

        // 是否喝酒
        this.doDrink = data.user.doDrink;
        if (this.doDrink == null) {
          this.doDrink == null;
        } else {
          this.doDrinks.forEach((item) => {
            if (this.doDrink == item.label) {
              this.doDrink = item.value;
            }
          });
        }

        this.doSmake = data.user.doSmake;
        if (this.doSmake == null) {
          this.doSmake == null;
        } else {
          this.doSmakes.forEach((item) => {
            if (this.doSmake == item.label) {
              this.doSmake = item.value;
            }
          });
        }

        this.houseStatu = data.user.houseStatus;
        if (this.houseStatu == null) {
          this.houseStatu == null;
        } else {
          this.houseStatus.forEach((item) => {
            if (this.houseStatu == item.label) {
              this.houseStatu = item.value;
            }
          });
        }
      }
    },
    // 更新数据
    async bcjx() {
      this.buyCarStatus.forEach((item) => {
        if (this.buyCarStatu == item.value) {
          this.buyCarStatu = item.label;
        }
      });

      this.doDrinks.forEach((item) => {
        if (this.doDrink == item.value) {
          this.doDrink = item.label;
        }
      });

      this.doSmakes.forEach((item) => {
        if (this.doSmake == item.value) {
          this.doSmake = item.label;
        }
      });

      this.houseStatus.forEach((item) => {
        if (this.houseStatu == item.value) {
          this.houseStatu = item.label;
        }
      });
      //保存调接口
      let a = {
        doDrink: this.doDrink,
        doSmake: this.doSmake,
        buyCarStatus: this.buyCarStatu,
        houseStatus: this.houseStatu,
        occSmallCategory: this.workregion.occSmallCategory,
        occBigCategory: this.workregion.occBigCategory,
      };
      const { code } = await infoUpdate(a);
      if (code == 0) {
        this.$message({
          message: "修改成功",
          type: "success",
          offset: 100,
        });
        const G = await subTk();
        if (G.code == 0) {
          local.SessionSet("sub_tk", G.data);
        }
        setTimeout(() => {
          //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
          this.$router.push({
            path: `/n/user/_heartWord`,
          });
          //延迟时间：3秒
        }, 0);
      }
    },
    tg() {
      //保存调接口
      this.$router.push({
        path: `/n/user/_heartWord`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 880px;
  display: flex;
  height: 40px;
  align-items: center;
  margin-top: 16px;
  p {
    width: 116px;
    font-size: 16px;
    color: #666666;
  }
}
/deep/.el-input__inner {
  background-color: #f5f5f5 !important;
  border: none !important;
}
</style>

