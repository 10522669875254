<template>
  <div id="myDate">
    <div class="m-datepicker">
      <!-- 职业1 -->
      <div
        class="b-field-input province-box"
        @mouseenter="enter()"
        @mouseleave="leave()"
      >
        <div class="value default-value" ref="ii">
          {{ province == null ? "请选择" : province }}
        </div>
        <div v-show="provinceBtn" class="picker">
          <div class="picker-b">
            <div
              class="province"
              v-for="(item, index) in provinces"
              :key="index"
            >
              <div class="option" @click="al(item)">
                {{ item.dictLabel }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 市 -->
      <div v-show="yincang" class="b-field-input city">
        <div class="value default-value" ref="iL">
          {{ city == null ? "请选择" : city }}
        </div>
        <div class="ci-s" v-show="cityBtn">
          <div class="picker">
            <div
              v-for="(item, index) in Pcitys"
              :key="index"
              class="option"
              @click="ml(item)"
            >
              {{ item.dictLabel }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dict } from "@/api/my/my.js";
export default {
  props: ["data1"],
  data() {
    return {
      provinces: [],
      Citys: [],
      Pcitys: [],
      yincang: false,
      a: false,
      province: "",
      city: "",
      county: "",
      provinceId: null,
      cityId: null,

      provinceBtn: false,
      cityBtn: false,
    };
  },
  created() {
    this.cs();
  },
  methods: {
    //初始化数据
    async cs() {
      let a = {
        dictKey: "occ_category_big",
      };
      const { code, data } = await dict(a);
      if (code == 0) {
        data[0].children.forEach((item) => {
          this.provinces.push(item);
        });
      }
    },
    //职业-大
    al(item) {
      this.city = "";
      this.county = "";
      // this.yincang = true;
      this.provinceBtn = false;
      this.cityBtn = true;
      this.province = item.dictLabel;
      for (let i = 0; i < this.provinces.length; i++) {
        if (this.provinces[i].dictLabel == item.dictLabel) {
          this.Citys.push(this.provinces[i].children);
          for (let j = 0; j < this.Citys.length; j++) {
            console.log("000", this.Citys[j]);

            if (this.Citys[j] == undefined) {
              this.cityBtn = false; //点击完关闭列表
              this.yincang = false;
              this.Citys = [];
              this.Pcitys = [];
              // this.city = item.dictLabel;
              //数据
              let data = {
                occSmallCategory: "",
                occBigCategory: this.province,
              };
              //传递数据
              this.$emit("workData", data);
            } else {
              this.yincang = true;
              for (let z = 0; z < this.Citys[j].length; z++) {
                this.Pcitys.push(this.Citys[j][z]);
              }
            }
          }
        }
      }
    },
    // 职业-小
    ml(item) {
      this.cityBtn = false; //点击完关闭列表
      this.Citys = [];
      this.Pcitys = [];
      this.city = item.dictLabel;

      //数据
      let data = {
        occSmallCategory: this.city,
        occBigCategory: this.province,
      };
      //传递数据
      this.$emit("workData", data);
    },
    enter() {
      if (this.cityBtn) {
        this.provinceBtn = false;
      } else {
        this.provinceBtn = true;
      }
    },
    leave() {
      this.provinceBtn = false;
    },
  },
  watch: {
    data1(newValue, oldValue) {
      console.log("新", newValue, oldValue);
      this.province = newValue.occBigCategory;
      this.city = newValue.occSmallCategory;
      if (newValue.occSmallCategory == "") {
        this.yincang = false;
      } else {
        this.yincang = true;
      }
    },
    province(oldValue) {
      if (oldValue == null) {
        this.$refs.ii.style.color = "#C0C4CC";
      } else {
        this.$refs.ii.style.color = "#606266";
      }
    },
    city(oldValue) {
      if (oldValue == null) {
        this.$refs.iL.style.color = "#C0C4CC";
      } else {
        this.$refs.iL.style.color = "#606266";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.m-datepicker {
  position: relative;
  display: flex;
}
.m-datepicker .value {
  display: inline-block;
  margin-left: 10px;
  min-width: 110px;
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;
  padding: 0 14px;
  background-color: #f4f4f4;
  color: #333333;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-size: 14px;
}
.m-datepicker .select-value {
  color: #333333;
}
.m-datepicker .title {
  padding-bottom: 10px;
  line-height: 26px;
  text-align: center;
  font-weight: bold;
}
.m-datepicker .picker {
  width: 200px;
  font-size: 16px;
  height: 222px;
  overflow: auto;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 20;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.3);
  cursor: pointer;
  .option {
    height: 30px;
  }
  padding: 10px;
  .option:hover {
    background-color: #d9d9d9;
  }
  .picker-b {
    width: 100%;
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    .province {
      margin: 2px;
    }
  }
}

.province-box {
  margin-left: 0px;
  .value {
    margin-left: 0px;
  }
}
.city {
  position: relative;
  .ci-s {
    display: flex;
    .picker {
      width: 150px;
    }
  }
}
.option {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>